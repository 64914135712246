<template>
    <div class="modal-inspection" v-if="showModal">
        <div class="modal-inspection__content">
            <h1 class="modal-inspection__title">Confirm Room Inspection</h1>
            <div class="modal-inspection__buttons">
                <button class="button button--success button--small-padding" @click="updateRoomStatus">
                    Yes, Confirm Inspection
                </button>
                <button class="button button--primary button--small-padding" @click="closeModal">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { readyForInspectionServices } from '../Services/ReadyForInspectionServices'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'

const props = defineProps(['rooms', 'showModal'])
const emits = defineEmits(['updateList', 'closeModal'])
const { updateRoomsStatusService } = readyForInspectionServices()
const { sweetSuccess } = sweetAlerts()
const showModal = ref(false)

watch(() => props.showModal, value => {
    showModal.value = value
})

async function updateRoomStatus() {
    // * Dispath request
    const response = await updateRoomsStatusService(props.rooms)

    // * Validate if request not have errors
    if (response.hasErrors) return console.error(`Error updating rooms status: ${response.message}`)

    // * Dispath emits
    emits('updateList')
    emits('closeModal', false)

    // * Show sweet alert notification
    sweetSuccess(response.data.message)
}

function closeModal() {
    showModal.value = false

    // * Dispath emits
    emits('closeModal', false)
}
</script>