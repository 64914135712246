<template>
    <button class="button button button--secondary button--small-padding" @click.prevent="downloadEvidences" :disabled="!rooms.length">
        Download List
    </button>
</template>

<script setup>
import { readyForInspectionServices } from '../Services/ReadyForInspectionServices';

const props = defineProps(['rooms'])
const { downloadRoomReadyForInspectionList } = readyForInspectionServices()

async function downloadEvidences() {
    const response = await downloadRoomReadyForInspectionList(props.rooms)

    if (response.hasErrors) return console.error(`Error downloading rooms ready for inspection list: ${response.message}`)

    const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('target', '_blank')
    document.body.appendChild(fileLink)
    fileLink.click()
}
</script>