<template>
    <div class="hotel-feed-card__input-content">
        <input type="text" class="hotel-feed-card__message-input" placeholder="Send Message..."
            v-model="message.message" @keyup.enter="sendMessage">

        <a class="hotel-feed-card__send-button" href="#" @click="sendMessage">
            <img src="@/assets/icons/iconamoon_send-thin.svg" />
        </a>
    </div>
</template>

<script setup>
import { mainServices } from '../Services/MainServices'
import { reactive, watch } from 'vue'

const props = defineProps(['associateId', 'chatId'])
const emits = defineEmits(['sendedMessage'])
const { sendChatMessage } = mainServices()
const message = reactive({
    message: '',
    chatId: null
})

watch(() => props.chatId, (chatId) => {
    message.chatId = chatId
})

async function sendMessage() {
    if (!message.message) return

    const response = await sendChatMessage(message, props.associateId)

    if (response.hasErrors) return console.error(`Error sendding message: ${response.message}`)

    emits('sendedMessage', response.data.data)

    message.message = ""
}
</script>