<template>
    <router-link class="panel-left__link" to="/login" @click.prevent="logout">
        <img src="@/assets/icons/solar_logout-line-duotone.svg" />
        Logout
    </router-link>
</template>

<script setup>
import { mainServices } from '../Services/MainServices.js'
import { newMessagesNotificationsEvent } from '@/sockets-events/NewMessagesNotifications.js'
import { useClientStore } from '../../../stores/ClientStore'
import { associatesOnlineEvent } from '@/sockets-events/AssociatesOnlineEvent.js'

// * Join in new messages notifications event
const { leaveNewMessageNotificationEvent } = newMessagesNotificationsEvent()
const { logoutService } = mainServices()
const { loginStatus } = useClientStore()
const { leaveAssociatesOnlineEvent } = associatesOnlineEvent()

async function logout() {
    // * Dispatch logout request
    const response = await logoutService()

    // * Show error in console
    if (response.hasErrors) return console.error(`Error in logout service: ${response.message}`)

    // * Leave to new message notification event
    leaveNewMessageNotificationEvent()

    // * Leave to associates online event
    leaveAssociatesOnlineEvent()

    // * Reset ProSafe subscription and customer data in local storage
    loginStatus()
}
</script>