<template>
    <div class="top-nav__option top-nav--alert-option" @click="startRecording">
        <img src="@/assets/icons/vuesax/linear/alarm.svg" />

        <span v-if="!recording">Help</span>
        <span class="top-nav__recording-icon" v-else>
            <img src="@/assets/icons/vuesax/linear/dot-red.svg" alt="">
            Recording
        </span>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps(['recording'])
const recording = ref(false)

function startRecording() {
    recording.value = !recording.value
}
</script>