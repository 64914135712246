<template>
    <div class="active-associates-card">
        <div class="active-associates-card__top">
            <h5 class="active-associates-card__title">
                Active Associates
            </h5>

            <div class="active-associates-card__badge">
                <img class="active-associates-card__icon-status" src="@/assets/icons/green-point.svg" />
                Online
            </div>
        </div>

        <div class="active-associates-card__associates-list" v-if="associates.length">
            <div class="active-associates-card__associate" v-for="associate in associates" :key="associate.id">
                <div class="active-associates-card__left">
                    <img 
                        :class="[associate.haveAlert ? 'active-associates-card--alertIndicator' : '', 'active-associates-card__associate-image']"
                        :src="`${filesPath}${associate.picture}`" 
                        v-if="associate.picture"
                    />

                    <img 
                        :class="[associate.haveAlert ? 'active-associates-card--alertIndicator' : '', 'active-associates-card__associate-image']"
                        src="@/assets/images/user-profile.svg" 
                        v-else 
                    />


                    <div class="active-associates-card__associate-info">
                        <span class="active-associates-card__associate-name">
                            {{ associate.name }}
                        </span>
                        <span class="active-associates-card__associate-room">
                            <span v-if="associate.last_date_message">{{ associate.last_date_message }}</span>
                            <span v-else>Send Message</span>
                        </span>
                    </div>
                </div>

                <a href="#" class="active-associates-card__chat-link" @click="setAssociateIdEvent(associate.id)">
                    <div class="active-associates-card__unread-messages-badge" v-if="associate.new_messages">
                        {{ unreadMessagesBadge(associate.new_messages) }}
                    </div>

                    <img src="@/assets/icons/vuesax/linear/solar_chat-dots-broken.svg" alt="">
                </a>
            </div>
        </div>

        <div class="active-associates-card__empty-list" v-else>
            <img src="@/assets/icons/vuesax/linear/profile-circle.svg" alt="" width="15" height="15">
            There are currently no online associates...
        </div>
    </div>
</template>

<script setup>
import { mainServices } from '../Services/MainServices'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { useClientStore } from '../../../stores/ClientStore.js'
import { associatesOnlineEvent } from '@/sockets-events/AssociatesOnlineEvent.js'

const { getAssocaitesOnlineService } = mainServices()
const associates = ref([])
const filesPath = process.env.VUE_APP_FILES_PATH
const emits = defineEmits(['setAssociateId'])
const { client } = useClientStore()
const { joinAssociatesOnlineEvent } = associatesOnlineEvent()

onBeforeUnmount(() => {
    Echo.leave(`active-associates.${client.hotelId}`)
})

onMounted(async () => {
    // * Get associates list 
    associateOnline()

    // * Join in associates online event
    joinAssociatesOnlineEvent()
        .listen(".associates-list", () => {
            associateOnline()
        })
})

async function associateOnline() {
    const response = await getAssocaitesOnlineService()

    if (response.hasErrors) return console.error(`Error getting associates online: ${response.message}`)

    associates.value = response.data
}

function setAssociateIdEvent(associateId) {
    emits('setAssociateId', associateId)
}

function unreadMessagesBadge(messages) {
    if (messages > 9) return `9+`
    if ((messages <= 9)) return messages
}
</script>