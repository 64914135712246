<template>
    <AssociatesOnline @setAssociateId="(value) => { associateId = value }" v-if="!associateId" />
    <AssociatesChat :associate-id="associateId" @setAssociateId="(value) => { associateId = value }" v-else />
</template>

<script setup>
import AssociatesOnline from './AssociatesOnline.vue'
import AssociatesChat from './AssociatesChat.vue'
import { ref } from 'vue'

const associateId = ref(null)
</script>