<template>
    <a href="#" title="Download Evidences" @click.prevent="downloadEvidences">
        <img src="@/assets/icons/vuesax/linear/receive-square-green.svg" width="15" height="15" />
    </a>
</template>

<script setup>
import { incidentsServices } from '../Services/IncidentsServices'

const props = defineProps(['incidentId'])
const { downloadEvidencesService } = incidentsServices()

async function downloadEvidences() {
    const response = await downloadEvidencesService(props.incidentId)

    if (response.hasErrors) return console.error(`Error downloading evidences: ${response.message}`)

    const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'Evidences.zip');
    document.body.appendChild(fileLink)
    fileLink.click()
}
</script>