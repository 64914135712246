import { httpRequests } from '../../../helpers/HttpRequests'

export function mainServices() {
    const { getRequest, postFormRequest, postRequest, putRequest } = httpRequests()

    function getBilligPortalnUrl() {
        return getRequest('/web/stripe/billing_portal')
    }

    function logoutService() {
        return getRequest('/web_logout')
    }

    function getAssocaitesOnlineService() {
        return getRequest('/web/chat/associates_online')
    }

    function getChatMessagesService(associateId) {
        return getRequest(`/web/chat/${associateId}`)
    }

    function sendChatMessage(messages, associateId) {
        return postFormRequest(`/web/chat/send_message/${associateId}`, messages)
    }

    function updateNotificationsVolume(volumeObj) {
        return putRequest('/web/notifications_permissions/set_volume', volumeObj)
    }

    function getNotificationsVolume() {
        return getRequest('/web/notifications_permissions/get_volume')
    }

    return { 
        getBilligPortalnUrl, 
        logoutService, 
        getAssocaitesOnlineService, 
        getChatMessagesService, 
        sendChatMessage, 
        updateNotificationsVolume,
        getNotificationsVolume
    }
}