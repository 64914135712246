<template>
    <div class="incidents">
        <div class="table-title">
            <h1 class="table-title__title">Incidents</h1>

            <div class="table-title__filter-options">
                <!-- Records Table Filter Component -->
                <InputFilter v-model="words" />

                <div class="popup-options">
                    <button class="button button--primary button--small-padding">
                        <img src="@/assets/icons/vuesax/linear/setting-2.svg" width="15" height="15" alt="">
                        Options
                    </button>

                    <div class="popup-options__options-content">
                        <ul class="popup-options__options-list">
                            <li>
                                <router-link to="/incidents/add">Add Incident</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <IncidentsTable :filter-words="words" />
    </div>
</template>

<script setup>
import IncidentsTable from '../Components/IncidentsTable.vue'
import InputFilter from '@/common/InputFilter.vue'
import { ref } from 'vue'

let words = ref("");
</script>