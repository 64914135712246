<template>
    <div class="associates__list">
        <table class="table">
            <thead class="table__table-head">
                <th>Associate</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th>
                <th>Options</th>
            </thead>
            <tbody>
                <tr v-for="alert in alertsLogs">
                    <td class="associates__name">
                        <img src="@/assets/images/user-profile.svg" class="associates__image" width="40" height="40"
                            alt="" v-if="!alert.associate_image">

                        <img :src="filesPath + alert.associate_image" class="associates__image" width="40" height="40"
                            alt="" v-else>

                        <span>{{ alert.associate_name }}</span>
                    </td>
                    <td>{{ alert.alert_date }}</td>
                    <td>{{ alert.alertTime }}</td>
                    <td>
                        <div class="alerts-logs__status">
                            <span class="alerts-logs__dot alerts-logs--dot-closed" v-if="alert.status_id === 3"></span>

                            <span class="alerts-logs__dot alerts-logs--dot-pending" v-if="alert.status_id === 4"></span>

                            <span class="alerts-logs__dot alerts-logs--dot-pending" v-if="alert.status_id === 6"></span>

                            <span :class="alert.status_id === 6 ? 'alerts-logs__pending-text' : ''">{{ alert.status }}</span>
                        </div>
                    </td>
                    <td>
                        <div class="table__options">
                            <router-link :to="`/alert/report/${alert.id}`" title="Alert Report"
                                v-if="alert.status_id !== 4">
                                <img src="@/assets/icons/vuesax/linear/clipboard-text.svg" width="15" height="15" />
                            </router-link>

                            <router-link to="/associates_alert" title="Check Alert" v-if="alert.status_id === 4">
                                <img src="@/assets/icons/vuesax/linear/radar.svg" width="15" height="15" />
                            </router-link>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Table Paginator Component -->
    <TablePaginator :api-url="alertsLogsApi" :filter="filterWords" v-model="alertsLogs" />

    <!-- Empty table records component -->
    <TableEmptyData title="Alerts Log List Empty" message="We did not find alerts logs in your list..."
        v-if="!alertsLogs.length" />
</template>

<script setup>
import TablePaginator from '@/common/TablePaginator.vue'
import TableEmptyData from '@/common/TableEmptyData.vue'
import { ref } from 'vue'

const alertsLogsApi = `/web/alerts_logs`
const filesPath = process.env.VUE_APP_FILES_PATH

defineProps(['filterWords'])

let alertsLogs = ref([])
</script>