<template>
    <a href="#" title="Logout Associate" @click.prevent="logoutAssociate" v-if="associate.associateStatus">
        <img src="@/assets/icons/vuesax/linear/logout.svg" width="15" height="15" />
    </a>

    <a href="#" title="Logout Associate" v-else>
        <img src="@/assets/icons/vuesax/linear/logout-gray.svg" width="15" height="15" />
    </a>

    <!-- Notifications Component -->
    <Notifications ref="notification" />
</template>

<script setup>
import { ref } from 'vue'
import { associatesServices } from '../Services/AssociatesServices'
import { sweetAlerts } from '../../../helpers/SweetAlert'
import Notifications from '@/common/Notifications.vue'

const { sweetSuccess } = sweetAlerts()
const { logoutAssociateService } = associatesServices()
const props = defineProps(['associate'])
const emits = defineEmits(['logoutAssociate'])
const notification = ref(null);

async function logoutAssociate() {
    const response = await logoutAssociateService(props.associate.userId)

    if (response.hasErrors) {
        notification.value.sendNotification('error', 'Error', response.message)

        return console.error(`Error in logout service: ${response.message}`)
    } 

    emits('logoutAssociate')

    return sweetSuccess('Associate logout successful.')
}
</script>