<template>
    <div class="associates">
        <div class="table-title">
            <h1 class="table-title__title">Associates</h1>

            <div class="table-title__filter-options">
                <!-- Records Table Filter Component -->
                <InputFilter v-model="words" />

                <div class="popup-options">
                    <button class="button button--primary button--small-padding">
                        <img src="@/assets/icons/vuesax/linear/setting-2.svg" width="15" height="15" alt="">
                        Options
                    </button>

                    <div class="popup-options__options-content">
                        <ul class="popup-options__options-list">
                            <li>
                                <router-link to="/associates/add">Add Associate</router-link>
                            </li>
                            <li>
                                <a href="#" @click.prevent="showImportModal = true">
                                    Associates Import 
                                </a>
                            </li>
                            <li>
                                <router-link to="/associates/subscriptions">Associates Subscription</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Associates table component -->
        <AssociatesTable :filter-words="words" :refresh-table="refreshTable" />

        <!-- Associates import component -->
        <AssociatesImport :show-modal="showImportModal" @close-modal="closeImportModal" @refresh-table="refreshAssociatesTable" />
    </div>
</template>

<script setup>
import InputFilter from '@/common/InputFilter.vue'
import AssociatesTable from '../Components/AssociatesTable.vue'
import AssociatesImport from '../Components/AssociatesImport.vue';
import { ref } from 'vue'

let words = ref("")
const showImportModal = ref(false)
const refreshTable = ref(false)

function closeImportModal(value) {
    showImportModal.value = value
}

function refreshAssociatesTable(value) {
    refreshTable.value = value
}
</script>