import { httpRequests } from "../../../helpers/HttpRequests"

export function readyForInspectionServices() {
    const { getRequest, postRequest, putRequest } = httpRequests()

    function getRoomsService() {
        return getRequest('/web/rooms/ready_for_inspection')
    }

    function updateRoomsStatusService(rooms) {
        return putRequest(`/web/rooms/update_status`, rooms)
    }

    function downloadRoomReadyForInspectionList(rooms) {
        return postRequest('/web/rooms/download_list', rooms, { 'responseType': 'blob' })
    }

    return { getRoomsService, downloadRoomReadyForInspectionList, updateRoomsStatusService }
}