<template>
    <div class="notification-permissions__switch">
        <div class="notification-permissions__switch-label">
            <span v-if="permissions.soundVolume">Sound ON</span>
            <span v-else>Sound OFF</span>

            <label class="switch">
                <input 
                    type="checkbox" 
                    @change="updatePermissions" 
                    :checked="permissions.soundVolume" 
                />

                <span class="slider round"></span>
            </label>
        </div>
        <span class="notification-permissions__switch-description">
            Enable or disable sound notifications.
        </span>
    </div>
</template>

<script setup>
import { useNotificationPermissionsStore } from '@/stores/NotificationPermissionsStore.js'
import { notificationPermissionService } from '../Services/NotificationPermissionsService.js'

const { permissions } = useNotificationPermissionsStore()
const { setNotificationsPermissionsService } = notificationPermissionService()

async function updatePermissions(e) {
    // * Set volume value to permissions object
    if (e.target.checked) permissions.soundVolume = 1
    else permissions.soundVolume = 0

    // * Dispatch notification permissions service
    const response = await setNotificationsPermissionsService(permissions)

    // * Return errors in console
    if (response.hasErrors) return console.error(`Updating permissions error: ${response.message}`)
}
</script>