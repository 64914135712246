<template>
    <div class="main">
        <PanelLeft @selected-view="contentView" />

        <div class="content">
            <TopNav />

            <div class="content-view">
                <component :is="selectedView" />
            </div>

            <div class="panel-cards">
                <ActiveAssociatesCard />
                <ReadyForInspection />
            </div>
        </div>
    </div>

    <!-- Notch alert permissions -->
    <NotchPermissions />

    <!-- Modal notification permissions -->
    <NotificationPermissions />

    <!-- Mappedin test -->
    <!-- <MappedinContainer /> -->
</template>

<script setup>
import PanelLeft from '../Components/PanelLeft.vue'
import TopNav from '../Components/TopNav.vue'
import MappedinContainer from '../../Mappedin/Views/MappedinContainer.vue'
import ActiveAssociatesCard from '../Components/ActiveAssociatesCard.vue'
import ReadyForInspection from '../../RoomsReadyForInspections/Views/ReadyForInspection.vue'
import NotchPermissions from '@/common/NotchPermissions.vue'
import NotificationPermissions from '@/modules/NotificationPermissions/Views/NotificationPermissions.vue'
import { newMessagesNotificationsEvent } from '@/sockets-events/NewMessagesNotifications.js'
import { shallowRef, onMounted } from 'vue'

let selectedView = shallowRef(null)

// * Join in new messages notifications event
const { joinNewMessageNotificationEvent } = newMessagesNotificationsEvent()

onMounted(() => {
    joinNewMessageNotificationEvent()
})

// * Set content view
function contentView(view) {
    selectedView.value = view
}
</script>