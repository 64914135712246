<template>
    <div class="panel-left">
        <div class="panel-left__top">
            <img src="@/assets/images/prosafe-new-logo.svg" class="panel-left__logo" />

            <ul class="panel-left__options">
                <li class="panel-left__option">
                    <a href="#" :class="['panel-left__link', selectedLink == 'Home' ? 'panel-left--selected-link' : '']"
                        @click.prevent="selectView(RootView)">
                        <img src="@/assets/icons/iconamoon_home-thin.svg" />
                        Home
                    </a>
                </li>
                <li class="panel-left__option">
                    <a href="#"
                        :class="['panel-left__link', selectedLink == 'Associates' ? 'panel-left--selected-link' : '']"
                        @click.prevent="selectView(Associates)">
                        <img src="@/assets/icons/ph_user-circle-light.svg" />
                        Associates
                    </a>
                </li>
                <li class="panel-left__option">
                    <a href="#"
                        :class="['panel-left__link', selectedLink == 'AlertsLogs' ? 'panel-left--selected-link' : '']"
                        @click.prevent="selectView(AlertsLogs)">

                        <img src="@/assets/icons/ph_bell-simple-ringing-light.svg" />

                        <div class="panel-left__pending-alerts">
                            Alerts Log

                            <div class="panel-left__alerts-badge" v-if="alert.pending">
                                {{ totalPendingAlerts(alert.pending) }}
                            </div>
                        </div>
                    </a>
                </li>
                <li class="panel-left__option">
                    <a href="#"
                        :class="['panel-left__link', selectedLink == 'Incidents' ? 'panel-left--selected-link' : '']"
                        @click.prevent="selectView(Incidents)">
                        <img src="@/assets/icons/mdi-light_alert-octagon.svg" />
                        Incidents
                    </a>
                </li>
                <li class="panel-left__option">
                    <a href="#"
                        :class="['panel-left__link', selectedLink == 'HotelEmbargo' ? 'panel-left--selected-link' : '']"
                        @click.prevent="selectView(HotelEmbargo)">
                        <img src="@/assets/icons/ph_user-circle-minus-thin.svg" />
                        Hotel Embargo
                    </a>
                </li>
            </ul>
        </div>

        <ul class="panel-left__options">
            <li class="panel-left__option">
                <a href="#" class="panel-left__link" @click="modalPermissions">
                    <img src="@/assets/icons/vuesax/linear/setting-3-white.svg" />
                    Permissions
                </a>
            </li>
            <li class="panel-left__option">
                <router-link class="panel-left__link" to="/hotel_setup">
                    <img src="@/assets/icons/iconamoon_settings-thin.svg" />
                    Hotel Setup
                </router-link>
            </li>
            <li class="panel-left__option">
                <LogoutOption />
            </li>
        </ul>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import RootView from '@/modules/Home/Views/RootView.vue'
import Associates from '@/modules/Associates/Views/Associates.vue'
import AlertsLogs from '@/modules/AlertsLogs/Views/AlertsLogs.vue'
import Incidents from '@/modules/Incidents/Views/Incidents.vue'
import HotelEmbargo from '@/modules/HotelEmbargo/Views/HotelEmbargo.vue'
import { useLocalStorage } from '@vueuse/core'
import { useNotificationPermissionsStore } from '@/stores/NotificationPermissionsStore.js'
import { usePanicAlertStore } from '@/stores/PanicAlertStore.js'
import LogoutOption from './LogoutOption.vue'

const emit = defineEmits(['selectedView'])
const currentView = ref(useLocalStorage('selectedView', { view: '' }))
const { modalPermissions } = useNotificationPermissionsStore()
const { alert } = usePanicAlertStore()
let selectedLink = ref("Home")

onMounted(() => {
    if (!currentView.value.view) selectView(RootView)
    if (currentView.value.view === 'Home') selectView(RootView)
    if (currentView.value.view === 'Associates') selectView(Associates)
    if (currentView.value.view === 'AlertsLogs') selectView(AlertsLogs)
    if (currentView.value.view === 'Incidents') selectView(Incidents)
    if (currentView.value.view === 'HotelEmbargo') selectView(HotelEmbargo)
})

// * Select and emit view event
function selectView(view) {
    if (view === RootView) {
        selectedLink.value = 'Home'
        currentView.value.view = 'Home'
    }

    if (view === Associates) {
        selectedLink.value = 'Associates'
        currentView.value.view = 'Associates'
    }

    if (view === AlertsLogs) {
        selectedLink.value = 'AlertsLogs'
        currentView.value.view = 'AlertsLogs'
    }

    if (view === Incidents) {
        selectedLink.value = 'Incidents'
        currentView.value.view = 'Incidents'
    }

    if (view === HotelEmbargo) {
        selectedLink.value = 'HotelEmbargo'
        currentView.value.view = 'HotelEmbargo'
    }

    emit('selectedView', view)
}

// * Set total pending alerts
function totalPendingAlerts(alerts) {
    return alerts > 9 ? '9+' : alerts
}
</script>