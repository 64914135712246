<template>
    <div class="hotel-embargo">
        <div class="table-title">
            <h1 class="table-title__title">Hotel Embargo</h1>

            <div class="table-title__filter-options">
                <!-- Records Table Filter Component -->
                <InputFilter v-model="filterWorwds" />

                <div class="popup-options">
                    <button class="button button--primary button--small-padding">
                        <img src="@/assets/icons/vuesax/linear/setting-2.svg" width="15" height="15" alt="">
                        Options
                    </button>

                    <div class="popup-options__options-content">
                        <ul class="popup-options__options-list">
                            <li>
                                <router-link to="/hotel_embargo/add">Add Hotel Embargo</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="hotel-embargo__list">
            <table class="table">
                <thead class="table__table-head">
                    <th>Full Name</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Options</th>
                </thead>
                <tbody>
                    <tr v-for="hotelEmbargo in hotelEmbargos" :key="hotelEmbargo.id">
                        <td>
                            {{ hotelEmbargo.individualName }}
                        </td>
                        <td>
                            {{ hotelEmbargo.embargoDate }}
                        </td>
                        <td>
                            {{ hotelEmbargo.embargoTime }}
                        </td>
                        <td>
                            <div class="table__options">
                                <DownloadReport :embargo-id="hotelEmbargo.id" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Table Paginator Component -->
        <TablePaginator :api-url="hotelEmbargoApi" :filter="filterWorwds" v-model="hotelEmbargos" />

        <!-- Empty table records component -->
        <TableEmptyData title="Hotel Embargo List Empty" message="We did not find hotel embargos in your list..."
            v-if="!hotelEmbargos.length" />
    </div>
</template>

<script setup>
import TablePaginator from '@/common/TablePaginator.vue'
import TableEmptyData from '@/common/TableEmptyData.vue'
import InputFilter from '@/common/InputFilter.vue'
import DownloadReport from '../Components/DownloadReport.vue'
import { ref } from 'vue'

const hotelEmbargoApi = `/web/hotel_embargo`
let hotelEmbargos = ref([])
let filterWorwds = ref("");
</script>