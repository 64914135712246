<template>
    <a href="#" title="Embargo Report" @click.prevent="downloadReport">
        <img src="@/assets/icons/vuesax/linear/note.svg" width="15" height="15" />
    </a>
</template>

<script setup>
import { hotelEmbargoServices } from '../Services/HotelEmbargoServices.js'

const props = defineProps(['embargoId'])
const { showPdfService } = hotelEmbargoServices()

async function downloadReport() {
    const response = await showPdfService(props.embargoId)

    if (response.hasErrors) return console.error(`Error downloading report: ${response.message}`)

    const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL;
    fileLink.setAttribute('target', '_blank')
    document.body.appendChild(fileLink)
    fileLink.click()
}
</script>