<template>
    <!-- Records Table Filter Component -->
    <InputFilter class="associates-logs__input-filter" v-model="words" />

    <div class="associates-logs__locations-scroll">
        <div class="associates-logs__empty-logs" v-if="!roomsLogs.length">
            We did not find room logs in your list...
        </div>

        <div class="associates-logs__location" v-for="log in roomsLogs" :key="log.id">
            <div class="associates-logs__location-item">
                <span class="associates-logs__location-title">
                    Room:
                </span>
                <span class="associates-logs__location-info">
                    {{ log.roomName }}
                </span>
            </div>

            <div class="associates-logs__location-item">
                <span class="associates-logs__location-title">
                    Floor:
                </span>
                <span class="associates-logs__location-info">
                    {{ log.floorName }}
                </span>
            </div>

            <div class="associates-logs__location-item">
                <span class="associates-logs__location-title">
                    Elapsed Time:
                </span>
                <span class="associates-logs__location-info">
                    {{ log.timeElapsed }}
                </span>
            </div>

            <div class="associates-logs__location-item">
                <span class="associates-logs__location-title">
                    Date:
                </span>
                <span class="associates-logs__location-info">
                    {{ log.date }}
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import InputFilter from '@/common/InputFilter.vue'
import { ref, watch, onMounted, nextTick } from 'vue'
import { associatesServices } from '../Services/AssociatesServices.js'

const props = defineProps(['associateId'])
const nextPage = ref(null)
const roomsLogs = ref([])
const words = ref('')
const { getAssociateRoomsLogsService } = associatesServices()
const path = (words) => { return `/web/associates/rooms_logs/${props.associateId}?query=${words}&page=1` }

watch(words, async () => {
    // * Set nextPage to null
    nextPage.value = null

    // * Get associates rooms logs
    const logs = await getAssociateRoomsLogs()

    // * Reset associates rooms logs array
    roomsLogs.value = []

    // * Set associates rooms logs
    setRoomsLogs(logs)
})


onMounted(async () => {
    // * Get associates rooms logs
    const logs = await getAssociateRoomsLogs()

    // * Set associates rooms logs
    setRoomsLogs(logs)
})

async function getAssociateRoomsLogs() {
    // * Define roomsLogsPath path
    const roomsLogsPath = nextPage.value ? nextPage.value.split(`${process.env.VUE_APP_BASE_PATH}/api`)[1] : path(words.value)

    // * Send request
    const response = await getAssociateRoomsLogsService(roomsLogsPath)

    // * Validate errors
    if (response.hasErrors) return console.error(`Error getting associate rooms logs: ${response.message}`)

    return response.data
}

async function setRoomsLogs(logs) {
    // * Set next page url
    nextPage.value = logs.roomsLogs.next_page_url

    // * Set rooms logs records
    roomsLogs.value.push(...logs.roomsLogs.data)

    // * Load dom data
    await nextTick()

    // * Call intersection observer function if roomsLogs have records
    if (logs.roomsLogs.data.length) intersectionOberserver()
}

// * Intersection Observer function 
async function intersectionOberserver() {
    const rootElement = document.querySelector(".associates-logs__locations-scroll")
    const observaleElement = Array.from(document.querySelectorAll(".associates-logs__location")).pop()

    // * Observer options
    const options = {
        root: rootElement,
        rootMargin: "0px 0px 90% 0px",
    }

    // * Make observer element
    const roomsListObserver = new IntersectionObserver(intersectedElement, options)

    // * Init observer element
    roomsListObserver.observe(observaleElement)
}

// * Callback observable element
async function intersectedElement(entrie, observer) {
    const [{ isIntersecting, target }] = entrie

    // * Set intersection into new group rooms
    if (isIntersecting && nextPage.value) {
        // * Get associates rooms logs
        const logs = await getAssociateRoomsLogs()

        // * Set associates rooms logs
        setRoomsLogs(logs)
    }

    // * Stop observer element
    if (isIntersecting && !nextPage.value) observer.unobserve(target)
}
</script>