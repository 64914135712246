<template>
    <div class="associates-logs" v-if="showModal">
        <div class="associates-logs__content">
            <h1 class="associates-logs__title">
                {{ associateName }}
            </h1>

            <ModalButtons @selected-view="setView" />

            <RoomsLogs :associate-id="associateId" v-if="selectedView == 'rooms'" />
            <AreasLogs :associate-id="associateId" v-if="selectedView == 'areas'" />

            <button class="button button--primary" @click="closeModal">
                Close
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import RoomsLogs from './RoomsLogs.vue'
import AreasLogs from './AreasLogs.vue'
import ModalButtons from './ModalButtons.vue'
import { associatesServices } from '../Services/AssociatesServices'

const props = defineProps(['associateId'])
const emits = defineEmits(['closeModal'])
const associateName = ref('')
const showModal = ref(false)
const selectedView = ref()
const { getAssociateService } = associatesServices()

watch(() => props.associateId, async (associateId) => {
    if (associateId) {
        await getAssociate()
        showModal.value = true
    } else {
        showModal.value = false
    }
})

async function getAssociate() {
    const response = await getAssociateService(props.associateId)

    if (response.hasErrors) return console.error(`Error getting associate information: ${response.message}`)

    associateName.value = response.data.associateName
}

function setView(view) {
    selectedView.value = view
}

function closeModal() {
    emits('closeModal')
}
</script>