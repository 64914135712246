<template>
    <observable-rooms-list @room-obj="setRoomObj" v-if="!roomObj" />
    <room-logs-table @room-obj="setRoomObj" :room-obj="roomObj" v-else />
</template>

<script setup>
import ObservableRoomsList from '../Components/ObservableRoomsList.vue'
import RoomLogsTable from '../Components/RoomLogsTable.vue'
import { ref } from 'vue'

const roomObj = ref(null)

function setRoomObj(room) {
    roomObj.value = room
}
</script>