<template>
    <div class="alerts-logs">
        <div class="table-title">
            <h1 class="table-title__title">Alerts Log</h1>

            <InputFilter v-model="words" />
        </div>

        <AlertsLogsTable :filter-words="words" />
    </div>
</template>

<script setup>

import InputFilter from '@/common/InputFilter.vue'
import AlertsLogsTable from '../Components/AlertsLogsTable.vue'
import { ref } from 'vue'

const words = ref("")
</script>
