import { httpRequests } from '../../../helpers/HttpRequests.js'

export function incidentsServices() {
  const { getRequest, postRequest, postFormRequest, httpErrors, resetErrors } = httpRequests();
  const errors = httpErrors

  function getIncidentsService() {
    return getRequest('/web/incidents');
  }

  function showPdfService(incidentId) {
    return getRequest(`/web/incidents/show_pdf/${incidentId}`, { 'responseType': 'blob' });
  }

  function downloadEvidencesService(incidentId) {
    return getRequest(`/web/incidents/download_evidences/${incidentId}`, { 'responseType': 'blob' });
  }

  function addIncidentService(incident) {
    return postFormRequest('/web/incidents/add', incident)
  }

  function addEvidencesService(evidences) {
    return postFormRequest('/web/incidents/add_evidences', evidences)
  }

  function validateIncidentFormService(incident) {
    return postRequest('/web/incidents/incident_validation', incident)
  }

  function validatePersonsInvolvedFormService(persons) {
    return postRequest('/web/incidents/persons_involved_validation', persons)
  }

  function validateWitnessesFormService(witnesses) {
    return postRequest('/web/incidents/witnesses_validation', witnesses)
  }

  return {
    getIncidentsService,
    showPdfService,
    downloadEvidencesService,
    addEvidencesService,
    addIncidentService,
    validateIncidentFormService,
    validatePersonsInvolvedFormService,
    validateWitnessesFormService,
    errors,
    resetErrors
  }
}
