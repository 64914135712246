import { httpRequests } from '../../../helpers/HttpRequests.js'

export function hotelEmbargoServices() {
    const { getRequest, postRequest, putRequest, httpErrors } = httpRequests();
    const errors = httpErrors

    function getHotelEmbargoService() {
        return getRequest('/web/hotel_embargo');
    }

    function addHotelEmbargoService(hotelEmbargo) {
        return postRequest('/web/hotel_embargo/add', hotelEmbargo)
    }

    function updateHotelEmbargoService(hotelEmbargoId, hotelEmbargo) {
        return putRequest(`/web/hotel_embargo/update/${hotelEmbargoId}`, hotelEmbargo)
    }

    function getIncidentsService() {
        return getRequest('/web/incidents');
    }

    function showPdfService(embargoId) {
        return getRequest(`/web/hotel_embargo/show_pdf/${embargoId}`, { 'responseType': 'blob' });
    }

    return {
        getHotelEmbargoService,
        addHotelEmbargoService,
        updateHotelEmbargoService,
        getIncidentsService,
        showPdfService,
        errors
    }
}
