<template>
    <div class="notch-permissions" v-if="!permissions.notificationPermission && !alert.panicAlertStatus || !permissions.soundPermission && !alert.panicAlertStatus">
        <p>
            To receive emergency notifications, please make sure you have enabled all 
            <a href="#" class="notch-permissions__link" @click.prevent="modalPermissions">permissions</a>.
        </p>
    </div>
</template>

<script setup>
import { useNotificationPermissionsStore } from '@/stores/NotificationPermissionsStore.js'
import { usePanicAlertStore } from '@/stores/PanicAlertStore.js'

const { permissions, modalPermissions } = useNotificationPermissionsStore()
const { alert } = usePanicAlertStore()
</script>