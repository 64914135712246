<template>
    <div class="notification-permissions__switch">
        <div class="notification-permissions__switch-label">
            <span>Notifications Permissions</span>

            <label class="switch">
                <input 
                    type="checkbox" 
                    id="notification-checkbox" 
                    @change="updatePermissions" 
                    :checked="permissions.notificationPermission"
                    :disabled="permissions.notificationPermission" 
                />
                    
                <span class="slider round"></span>
            </label>
        </div>
        <span class="notification-permissions__switch-description">
            Permission to receive notifications
        </span>
    </div>
</template>

<script setup>
import { useNotificationPermissionsStore } from '@/stores/NotificationPermissionsStore.js'
import { notificationPermissionService } from '../Services/NotificationPermissionsService.js'
import { onMounted, nextTick } from 'vue'

const { permissions } = useNotificationPermissionsStore()
const { setNotificationsPermissionsService } = notificationPermissionService()

onMounted(() => { checkPermissions() })

// * Set notification permissions
async function checkPermissions() {
    // * Await to load elements
    await nextTick()

    // * Get checkbox notification permissions values
    const checkboxElement = document.querySelector('#notification-checkbox')

    //  * Check notification permissions
    if (Notification.permission === 'denied') permissions.notificationPermission = false
    if (Notification.permission === 'default') permissions.notificationPermission = false
    if (Notification.permission === 'granted') permissions.notificationPermission = true

    // * Set value to checkbox element
    checkboxElement.checked = permissions.notificationPermission

    // * Dispatch notification permissions service
    const response = await setNotificationsPermissionsService(permissions)

    // * Return errors in console
    if (response.hasErrors) return console.error(`Updating permissions error: ${response.message}`)
}

async function updatePermissions(e) {
    const checkStatus = e.target.checked

    if (checkStatus) {
        // * Request to api notification to get selected option for the client
        await Notification.requestPermission()

        // * Check notification permissions
        checkPermissions()
    }
}
</script>