<template>
    <div class="hotel-import" v-if="showModal">
        <div class="hotel-import__modal">
            <div class="hotel-import__modal-content">
                <h1 class="hotel-import__modal-title">Associates Import</h1>

                <div class="hotel-import__modal-file">
                    <label for="excel-file"
                        :class="[!file ? 'hotel-import--border-default' : 'hotel-import--border-green', 'hotel-import__file']">
                        <span v-if="!placeHolderName">Click To Select Your Associates File</span>
                        <span v-else>{{ placeHolderName }}</span>

                        <input type="file" id="excel-file" @change="setFile">
                    </label>

                    <a href="./files/associates_import_template.xlsx" class="hotel-import__file-template" v-if="!file"
                        download>
                        <img src="@/assets/icons/vuesax/linear/receive-square-blue.svg" alt="">
                        Download Template
                    </a>

                    <div class="hotel-import__errors" v-if="errors.hasErrors">
                        <span>
                            {{ errors.message }}
                        </span>
                    </div>
                </div>

                <div class="hotel-import__buttons">
                    <button :class="[file ? 'button--success' : 'button--disabled', 'button']" :disabled="!file"
                        @click="loadAssociates" v-if="!showBtnLoader">
                        Load My Associates
                    </button>

                    <ButtonTextLoader loader-text="Importing Hotel"
                        button-class="button button--default hotel-import__button-loader" :is-loading="showBtnLoader"
                        v-if="showBtnLoader" />

                    <button class="button button--primary" @click="closeModal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { associatesServices } from "../Services/AssociatesServices"
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'

const file = ref(null)
const props = defineProps(['showModal'])
const emits = defineEmits(['closeModal', 'refreshTable'])
const placeHolderName = ref(null)
const { importAssociatesService, errors, resetErrors } = associatesServices()
const showModal = ref(false)
const { sweetSuccess } = sweetAlerts()
const showBtnLoader = ref(false)

watch(() => props.showModal, (value) => {
    showModal.value = value
})

function setFile(event) {
    const excelFile = event.target.files[0]

    file.value = { associatesFile: excelFile }
    placeHolderName.value = excelFile.name
}

async function loadAssociates() {
    showBtnLoader.value = true

    // * Request hotel import service
    const response = await importAssociatesService(file.value)

    // * Return error in console
    if (response.hasErrors) {
        showBtnLoader.value = false
        return console.error(`Error importing associates: ${response.message}`)
    }

    // * Close hotel import modal
    closeModal()

    // * Send emit to refresh table
    emits('refreshTable', true)

    // * Show sweet alert success
    sweetSuccess(response.data.message)
}

function closeModal() {
    // * Close hotel import modal
    emits('closeModal', false)

    // * Send emit to refresh table
    emits('refreshTable', false)
    
    file.value = null
    placeHolderName.value = null
    showBtnLoader.value = false

    resetErrors()
}
</script>