<template>
    <div class="room-detail">
        <div class="table-title">
            <a href="#" @click.prevent="setRoomObj" class="room-detail__back-option">
                <img src="@/assets/icons/vuesax/linear/arrow-left.svg" alt="">
                <h1 class="table-title__title">Room Logs - {{ roomObj.roomName }}</h1>
            </a>

            <InputFilter v-model="filterWords" />
        </div>

        <div class="room-detail__list" v-if="roomLogs.length">
            <table class="table">
                <thead class="table__table-head">
                    <th>Associate</th>
                    <th>Service Time</th>
                    <th>Status</th>
                    <th>Date</th>
                </thead>
                <tbody>
                    <tr v-for="(log, index) in roomLogs" :key="index">
                        <td class="room-detail__associate-name">
                            <img src="@/assets/images/user-profile.svg" class="room-detail__associates-image" width="30" height="30"
                                alt="" v-if="!log.associateImage">

                            <img :src="filesPath + log.associateImage" alt="" width="30" height="30"
                                class="room-detail__associates-image" v-else>

                            <span>{{ log.associateName }}</span>
                        </td>
                        <td>
                            <span v-if="log.statusId == 2">--:--</span>
                            <span v-else>{{ log.timeElapsed }}</span>
                        </td>
                        <td>
                            <div class="room-detail__status">
                                <img src="@/assets/icons/dot-green.svg" v-if="log.statusId === 3" />

                                <img src="@/assets/icons/dot-yellow.svg" v-if="log.statusId == 2" />

                                {{ log.statusName }}
                            </div>
                        </td>
                        <td>{{ log.date }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Table Paginator Component -->
        <TablePaginator :api-url="roomsLogsListApi" :filter="filterWords" v-model="roomLogs" />

        <!-- Empty table records component -->
        <TableEmptyData title="Room Logs List Empty" message="We did not find room logs in your list..."
            v-if="!roomLogs.length" />
    </div>
</template>

<script setup>
import TablePaginator from '@/common/TablePaginator.vue'
import TableEmptyData from '@/common/TableEmptyData.vue'
import InputFilter from '@/common/InputFilter.vue';
import { ref } from 'vue'

const props = defineProps(['roomObj'])
const emits = defineEmits(['roomObj'])
const roomsLogsListApi = `/web/rooms/logs/${props.roomObj.roomId}`
const filesPath = process.env.VUE_APP_FILES_PATH
const filterWords = ref("")
const roomLogs = ref([])

// * Emit event roomObj
function setRoomObj() {
    emits('roomObj', null)
}
</script>