import { reactive } from 'vue'

export const chatModel = (fields) => {
    return reactive({
        chatId: fields?.chat_id ?? null,
        associate: {
            name: fields?.associate.associateName,
            imagePath: fields?.associate.associateImagePath
        },
        messages: fields?.messages.data ?? []
    })
}