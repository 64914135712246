import { httpRequests } from '../../../helpers/HttpRequests'

export function notificationPermissionService() {
    const { postRequest } = httpRequests()

    function setNotificationsPermissionsService(permissions) {
        return postRequest(`/web/notifications_permissions/set`, permissions)
    }

    return { setNotificationsPermissionsService }
}