import { httpRequests } from '../../../helpers/HttpRequests';

export function homeServices() {
    const { getRequest, httpErrors } = httpRequests();
    const errors = httpErrors;

    function getRooms(page) {
        return getRequest(page);
    }

    return { getRooms, errors }
}
