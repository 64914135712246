<template>
    <div class="associates-logs__buttons">
        <button :class="selectedButton == 'rooms' ? 'associates-logs__active-button' : 'associates-logs__disble-button'" @click="selectButton('rooms')">
            Room Logs
        </button>
        <button :class="selectedButton == 'areas' ? 'associates-logs__active-button' : 'associates-logs__disble-button'" @click="selectButton('areas')">
            Area Logs
        </button>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const selectedButton = ref('rooms')
const emits = defineEmits(['selectedView'])

onMounted(() => { emits('selectedView', selectedButton.value) })

function selectButton(button) {
    selectedButton.value = button
    emits('selectedView', selectedButton.value)
}
</script>