import { useClientStore } from '@/stores/ClientStore.js'

export function associatesOnlineEvent() {
    const { client } = useClientStore()

    // * Subscribe to associates online event
    function joinAssociatesOnlineEvent() {
        console.log('Join in active associates channel ✅')

        return Echo.private(`active-associates.${client.hotelId}`)
    }

    // * Leave to associates online event
    function leaveAssociatesOnlineEvent() {
        return Echo.leave(`active-associates.${client.hotelId}`)
    }

    return { joinAssociatesOnlineEvent, leaveAssociatesOnlineEvent }
}