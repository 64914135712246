<template>
    <div class="incidents__list">
        <table class="table">
            <thead class="table__table-head">
                <th>Incident Number</th>
                <th>Date</th>
                <th>Time</th>
                <th>Options</th>
            </thead>
            <tbody>
                <tr v-for="incident in incidents">
                    <td>
                        {{ incident.incident_number }}
                    </td>
                    <td>{{ incident.incident_date }}</td>
                    <td>{{ incident.incident_time }}</td>
                    <td>
                        <div class="table__options">
                            <DownloadReport :incident-id="incident.id" />
                            <DownloadEvidences :incident-id="incident.id" v-if="incident.totalIncidents" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Table Paginator Component -->
    <TablePaginator :api-url="incidentstApi" :filter="filterWords" v-model="incidents" />


    <!-- Empty table records component -->
    <TableEmptyData title="Incidents List Empty" message="We did not find incidents in your list..."
        v-if="!incidents.length" />
</template>

<script setup>
import TablePaginator from '@/common/TablePaginator.vue'
import TableEmptyData from '@/common/TableEmptyData.vue'
import DownloadReport from './DownloadReport.vue'
import DownloadEvidences from './DownloadEvidences.vue'
import { ref } from 'vue'

defineProps(['filterWords'])

const incidentstApi = `/web/incidents`
const incidents = ref([])
</script>